import React from 'react'

function Index() {
    return (
        <div className="page404">
            <p>Oops! page not found</p>
            <h1>404</h1>
            <p>we are sorry, but the page you requested was not found</p>
        </div>
    )
}

export default Index